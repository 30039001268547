<!-- Brand Logo -->
<a
    [routerLink]="['/']"
    style="border-bottom: 0; padding: 1rem 0px 0px 0px"
    class="brand-link text-center"
>
    <pf-image
        src="assets/img/logo.png"
        alt="Logo Te-Apoyo"
        [height]="[size]"
        [width]="[size]"
    ></pf-image>
</a>

<!-- Sidebar -->
<div style="padding-right: 0" class="sidebar">
    <!-- Sidebar user (optional) -->
    <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <pf-image
                [src]="user.picture"
                fallback-src="assets/img/default-profile.png"
                class="img-circle"
                alt="User Image"
                width="34"
                height="34"
                rounded
            ></pf-image>
        </div>
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block">
                {{ user.email }}
            </a>
        </div>
    </div> -->

    <!-- <div class="mt-3">
        <app-sidebar-search></app-sidebar-search>
    </div> -->

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu; let i = index"
                [ngClass]="'menu-item' + i"
                [menuItem]="item"
            ></app-menu-item>
        </ul>
    </nav>
</div>
