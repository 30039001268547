<div class="modal fade" id="modalAhorro" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ titulo }}</h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="mb-3">
                        <label for="tipo_ahorro" class="form-label"
                            >Tipo de ahorro</label
                        >
                        <select formControlName="tipo_ahorro" class="form-control">
                            <option selected value="-1">Seleccione</option>
                            <option
                                *ngFor="let tipo of tipo_ahorro"
                                [value]="tipo.id"
                            >
                                {{ tipo.descripcion }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="fecha_limite" class="form-label"
                            >Fecha límite</label
                        >
                        <input
                            autocomplete="off"
                            formControlName="fecha_limite"
                            type="date"
                            class="form-control"
                            placeholder="Fecha límite del ahorro"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="recaudado" class="form-label"
                            >Recaudado</label
                        >
                        <input
                            autocomplete="off"
                            formControlName="recaudado"
                            type="number"
                            class="form-control"
                            placeholder="Cantidad recaudada hasta ahora"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="meta" class="form-label">Meta</label>
                        <input
                            autocomplete="off"
                            formControlName="meta"
                            type="number"
                            class="form-control"
                            placeholder="Meta del ahorro"
                        />
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                        <button
                            [disabled]="!form.valid"
                            type="submit"
                            class="btn btn-primary"
                        >
                            Guardar cambios
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
