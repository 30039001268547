<div class="modal fade" id="modalAhorro" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ titulo }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form" (ngSubmit)="submit()">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="rut" class="form-label">RUT</label>
                                <input autocomplete="off" formControlName="rut" type="text" class="form-control"
                                    placeholder="RUT" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="nombres" class="form-label">Nombres</label>
                                <input autocomplete="off" formControlName="nombres" type="text" class="form-control"
                                    placeholder="Nombres" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="apellidos" class="form-label">Apellidos</label>
                                <input autocomplete="off" formControlName="apellidos" type="text" class="form-control"
                                    placeholder="Apellidos" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="email" class="form-label">Email Administrador</label>
                                <input autocomplete="off" formControlName="email" type="email" class="form-control"
                                    placeholder="Email del usuario" />
                                <small>Se creará una cuenta con los datos del email y contraseña.</small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="password" class="form-label">Contraseña</label>
                                <input autocomplete="off" formControlName="password" type="password" class="form-control"
                                    placeholder="Contraseña del usuario" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="confirmPassword" class="form-label">Confirmar Contraseña</label>
                                <input autocomplete="off" formControlName="confirmPassword" type="password"
                                    class="form-control" placeholder="Confirmar Contraseña" />
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="nombreEmpresa" class="form-label">Nombre Empresa</label>
                        <input autocomplete="off" formControlName="nombreEmpresa" type="text" class="form-control"
                            placeholder="Nombre de la empresa" />
                    </div>

                    <div class="mb-3">
                        <label for="cantidad_colaboradores" class="form-label">Cantidad máxima de colaboradores</label>
                        <input autocomplete="off" formControlName="cantidad_colaboradores" type="text"
                            class="form-control" placeholder="Cantidad máxima de colaboradores" />
                    </div>

                    <div class="alert alert-info" *ngIf="form.invalid && form.dirty">
                        <ul>
                            <li *ngFor="let error of getFormErrors()">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button [disabled]="!form.valid" type="submit" class="btn btn-primary">Guardar cambios</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
