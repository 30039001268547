<button class="btn btn-info mx-2" data-toggle="modal" data-target="#massUploadModal">
  <i class="fas fa-users"></i> Carga Masiva de usuarios
</button>

<div class="modal fade" id="massUploadModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <form (submit)="submitFile()" [formGroup]="miForm">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="modalLabel">Carga Masiva de Usuarios</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">

                  <!-- Sección de Errores -->
                  <div *ngIf="errores.length > 0" class="alert alert-danger">
                      <strong><i class="fas fa-exclamation-triangle"></i> Errores encontrados:</strong>
                      <ul>
                          <li *ngFor="let error of errores">
                              Fila {{ error.fila }}: {{ error.mensaje }}
                          </li>
                      </ul>
                  </div>

                  <div *ngIf="rol === 'Administrador'">
                    <div class="form-group">
                        <label for="empresaSelect">Selecciona la empresa:</label>
                        <select id="empresaSelect" class="form-control" formControlName="empresa">
                            <option *ngFor="let empresa of listaEmpresa" [value]="empresa.id">{{empresa.nombre}}</option>
                        </select>
                    </div>
                </div>
                  <!-- Sección de Subida de Archivo -->
                  <div class="form-group">
                      <label for="file">Selecciona el archivo:</label>
                      <input type="file" id="file" formControlName="excel" (change)="onFileSelected($event)">
                  </div>

                  <!-- Sección de Descarga de Archivo de Ejemplo -->
                  <div class="mt-3">
                      <i class="fas fa-download"></i>
                      <a href="assets/excel/Example-01.xlsx" download="Carga masiva ejemplo.xlsx">Descargar archivo de prueba</a>
                  </div>

              </div>
              <div class="modal-footer">
                  <button type="submit" class="btn btn-success">Subir</button>
              </div>
          </div>
      </form>
  </div>
</div>
