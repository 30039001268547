import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-colaborador',
  templateUrl: './gestion-colaborador.component.html',
  styleUrls: ['./gestion-colaborador.component.scss']
})
export class GestionColaboradorComponent {

}
