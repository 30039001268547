<div class="modal fade" id="modalAddcupon" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ titulo }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="row">
                        <!-- Campo para codigo -->
                        <div class="col-md-4 mb-3">
                            <label for="codigo" class="form-label">Código</label>
                            <input autocomplete="off" formControlName="codigo" type="text" class="form-control" placeholder="Código del cupón" />
                        </div>

                        <!-- Campo para descripcion -->
                        <div class="col-md-8 mb-3">
                            <label for="descripcion" class="form-label">Descripcion</label>
                            <input autocomplete="off" formControlName="descripcion" type="text" class="form-control" placeholder="Descripción del cupón" />
                        </div>
                    </div>

                    <div class="row">
                        <!-- Campo para porcentaje -->
                        <div class="col-md-4 mb-3">
                            <label for="descuento" class="form-label">Descuento</label>
                            <input autocomplete="off" formControlName="descuento" min="0" type="number" class="form-control" placeholder="Porcentaje de descuento" />
                        </div>

                        <!-- Campo para tipo -->
                        <div class="col-md-4 mb-3">
                            <label for="tipo" class="form-label">Tipo</label>
                            <select formControlName="tipo" class="form-control">
                                <option *ngFor="let item of tiposCupon" [value]="item.name">{{ item.name }}</option>
                            </select>
                        </div>

                        <!-- Campo para inicio -->
                        <div class="col-md-4 mb-3">
                            <label for="fecha_inicio" class="form-label">Inicio</label>
                            <input autocomplete="off" formControlName="fecha_inicio" type="date" class="form-control" placeholder="Fecha de inicio" />
                        </div>

                        <!-- Campo para vencimiento -->
                        <div class="col-md-4 mb-3">
                            <label for="fecha_fin" class="form-label">Vencimiento</label>
                            <input autocomplete="off" formControlName="fecha_fin" type="date" class="form-control" placeholder="Fecha de vencimiento" />
                        </div>
                    </div>

                    <div class="alert alert-info" *ngIf="form.invalid && form.dirty">
                        <ul>
                            <li *ngFor="let error of getFormErrors()">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button type="submit" class="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
