// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    //uri_api: 'http://13.90.92.134:8000/api/v1/'
    uri_api: 'https://www.plataformadeautogestionfinanciera.cl/api/public/api/v1/',
    url_api_chatbot:'https://api-chatbot-ta.azurewebsites.net/api/chatbot'
    //uri_api: 'https://www.plataformadeautogestionfinanciera.cl/api/public/api/v1/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

//https://www.plataformadeautogestionfinanciera.cl/api/public/api/clear