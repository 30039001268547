<div class="container mt-5" *ngIf="!read">
    <div class="row">
      <div class="col-12 col-lg-3 text-center mb-4">
        <img style="max-width: 100%" src="assets/img/logo.png" alt="" />
      </div>
      <div class="col-12 col-lg-9">
  
        <div class="container mt-5">
          <div class="row">   
          <div class="col-lg-12 text-lg-right text-center">
            <h1 class="h1 text-principal">
              Test de
              <span class="mobile-break"><br></span> 
              <b>Personalidad Financiera</b>
          </h1>
          </div>
        </div>
  
          <div class="row">        
            <div class="col-lg-12 text-lg-right text-center text-principal">
              <h5>
                Contesta las siguientes preguntas para que obtengas tu personalidad financiera <b>TE-APOYO</b>
              </h5>
            </div>
          </div>
        </div>      
  
        <div class="row mt-3">
          
          <div class="col-12 col-lg-12 mb-4">
            <div class="card glass">
              <div class="card-header text-center">
                <div *ngIf="!verPersonalidad" class="h5"><b>¿Estás preparado?</b></div>
                <div *ngIf="verPersonalidad" class="h5"><b>Esta es tu personalidad financiera</b> | <a href="#" [routerLink]="['/login']" class="text-principal">Salir</a></div>
              </div>

              <div class="card-body">
                <p class="login-box-msg"><b></b></p>
                <p *ngIf="!verPersonalidad" style="text-align: justify" class="login-box-msg">
                  Con nuestro test completamente gratis descubrirás tu tipo de personalidad financiera.
                  ¡Haremos un análisis profundo de tus hábitos y mejoremos tus fortalezas financieras!
                </p>

                <div *ngIf="verPersonalidad" class="h4"><b>***{{ personalidad }}</b></div>
                <p *ngIf="verPersonalidad" style="text-align: justify" class="login-box-msg">
                  {{ definicion }} <b><h3 class="h3 text-principal"><a class="text-principal" href="https://www.te-apoyo.cl/planes" target="_blank">¡Aquí!</a></h3></b>
                </p>

                <p><small *ngIf="verPersonalidad" style="text-align: justify" class="login-box-msg">
                  <b>*Nota importante: </b>Estos tipos de "personalidad financiera predominante" no tienen la intención de ser una explicación exhaustiva de por qué las personas gastan, ahorran o manejan sus finanzas. Tampoco es un diagnóstico clínico y sólo ilustran algunas de las maneras distintas que las personas pueden pensar y actuar en cuanto a los asuntos económicos.
                </small></p>

              </div>

              <div class="text-right m-3" *ngIf="!verPersonalidad">
                <button [type]="'button'" [routerLink]="['/login']" class="btn btn-normal">
                  <i class="fas fa-chevron-left"></i>
                  Salir 
                </button>

                <button [type]="'button'" (click)="openModal();" class="btn btn-normal" style="margin-left: 10px;">
                  Comenzar
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>

        </div>
        <div class="row justify-content-end py-3">
          <h5 class="w-100 text-right text-main">Te Apoyo es la plataforma ideal para volver a tomar el control sobre tus finanzas</h5>
          <h6 class="w-100 text-right text-main">¡Te contamos por qué!</h6>
        </div>
      </div>
    </div>
</div>

<div class="container mt-5" *ngIf="read">
  <div class="row">
    <div class="col-12 col-lg-3 text-center mb-4">
      <img style="max-width: 100%" src="assets/img/logo.png" alt="" />
    </div>
    <div class="col-12 col-lg-9">

      <div class="container mt-5">
        <div class="row">   
        <div class="col-lg-12 text-lg-right text-center">
          <h1 class="h1 text-principal">
            Test de
            <span class="mobile-break"><br></span> 
            <b>Personalidad Financiera</b>
        </h1>
        </div>
      </div>

        <div class="row">        
          <div class="col-lg-12 text-lg-right text-center text-principal">
            <h5>
              Contesta las siguientes preguntas para que obtengas tu personalidad financiera <b>TE-APOYO</b>
            </h5>
          </div>
        </div>
      </div>      

      <div class="row mt-3">
        
        <div class="col-12 col-lg-12 mb-4">
          <div class="card glass">
            <div class="card-header text-center">
              <div class="h5"><b>Comienza tu test de personalidad financiera</b></div>
            </div>

            <div class="card-body">
              
              <form [formGroup]="form">
                <ul formArrayName="fields" *ngFor="let field of fieldArray.controls; let i = index" style="list-style-type: none;">
                  <li [formGroupName]="i" style="text-align: justify" class="login-box-msg">
                    <label>{{ i + 1 }}. {{ field.value.pregunta }}</label>
                    <input type="hidden" id="field{{i}}" formControlName="pregunta_id">

                    <div formArrayName="respuestas" *ngFor="let respuesta of field.get('respuestas').controls; let j = index">
                      <input type="radio" id="respuesta{{i}}{{j}}" name="respuesta{{i}}" [value]="respuesta.value.id" (change)="onRadioChange(i, $event)">
                      <span style="margin-left: 5px;">{{ respuesta.value.respuesta }}</span>
                    </div>

                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                  <button type="button" (click)="enviarRespuestas()" class="btn btn-normal">Enviar <i class="fas fa-check"></i></button>
                </div>
              </form>              
              
            </div>

          </div>
        </div>

      </div>
      <div class="row justify-content-end py-3">
        <h5 class="w-100 text-right text-main">Te Apoyo es la plataforma ideal para tomar el control sobre tus finanzas</h5>
      </div>
    </div>
  </div>
</div>

<app-modal-user-test #modalUserTest (preguntas)="preguntasRam($event)" (preuser)="preuser($event)"></app-modal-user-test>
<ngx-loading [show]="isLoading"></ngx-loading>
