<section class="content" style="margin-bottom: 15px">
    <main class="container mt-4">
        <div
            class="p-4 p-md-5 mb-4 text-white rounded"
            style="background-color: #60c8d3"
        >
            <div class="col-md-6 px-0">
                <h1 class="m-0 mb-3">
                    <strong>{{ blogs.title }}</strong>
                </h1>
                <p class="lead my-3 text-justify">{{ blogs.content }}</p>
            </div>
        </div>
        <div class="row g-5">
            <div class="col-md-8 bg-light rounded">
                <article class="blog-post text-justify">
                    <!-- <h2 class="m-0 text-main mb-3">
                        <strong>{{ blogs.title }}</strong>
                    </h2> -->
                    <p class="blog-post-meta">
                        {{ blogs.created_at | date: 'MMMM dd, yyyy' }}
                    </p>
                    <!-- <p [innerHTML]="blogs.description"></p> -->
                    <form [formGroup]="form" (submit)="guardar()">
                        <div class="editor">
                            <ngx-editor-menu
                                *ngIf="rol === 'Administrador'"
                                [editor]="editor"
                                [toolbar]="toolbar"
                            >
                            </ngx-editor-menu>
                            <ngx-editor
                                class="edit"
                                [editor]="editor"
                                formControlName="editorContent"
                            >
                            </ngx-editor>
                        </div>
                        <button
                            *ngIf="rol === 'Administrador'"
                            type="submit"
                            class="btn custom-primary-color mt-2"
                        >
                            Guardar
                        </button>
                    </form>
                </article>
            </div>
            <div class="col-md-4">
                <div class="position-sticky" style="top: 2rem">
                    <div class="p-4 mb-3 bg-light rounded">
                        <h4 class="text-main">Acerca de</h4>
                        <p class="mb-0 text-justify">
                            Te Apoyo es una aplicación diseñada para brindarte
                            apoyo en tus decisiones financieras. Nuestro
                            objetivo es ayudarte a tomar el control de tu futuro
                            financiero y a hacer que tus metas sean más
                            accesibles. Creemos en la importancia de la
                            educación financiera y en proporcionarte las
                            herramientas necesarias para tomar decisiones
                            informadas.
                        </p>
                    </div>

                    <div class="p-4 mb-3 bg-light rounded">
                        <h4 class="text-main">Contacto</h4>
                        <p class="mb-0 text-justify">
                            Si deseas ponerte en contacto con nosotros o
                            seguirnos en las redes sociales, aquí tienes los
                            enlaces:
                        </p>
                        <ul class="list-unstyled mt-2">
                            <li>
                                <a
                                    href="https://www.instagram.com/te_apoyo.cl"
                                    target="_blank"
                                >
                                    <i class="fab fa-instagram"></i> Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/71562380/admin/feed/posts"
                                    target="_blank"
                                >
                                    <i class="fab fa-linkedin"></i> LinkedIn
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/profile.php?id=100075845813948"
                                    target="_blank"
                                >
                                    <i class="fab fa-facebook"></i> Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://wa.me/56995386712"
                                    target="_blank"
                                >
                                    <i class="fab fa-whatsapp"></i> WhatsApp
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="p-4 bg-light rounded">
                        <div class="p-4 bg-light rounded">
                            <button
                                type="button"
                                class="btn custom-primary-color"
                                (click)="atras()"
                            >
                                <i class="fas fa-arrow-left"></i> Volver atrás
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</section>
<svg
    class="waves"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    shape-rendering="auto"
>
    <defs>
        <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 s 58-18 88-18 s 58 18 88 18 v44h-352z"
        />
    </defs>
    <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="#8ed6d7" />
        <!-- Color pastel principal -->
        <use xlink:href="#gentle-wave" x="48" y="3" fill="#b3e4e5" />
        <!-- Un poco más claro -->
        <use xlink:href="#gentle-wave" x="48" y="5" fill="#68c0c1" />
        <!-- Un poco más oscuro -->
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#c6f0f1" />
        <!-- Más claro -->
    </g>
</svg>
