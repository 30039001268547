<!-- <button *ngIf="!showChat" (click)="toggleChat()" class="chat-button btn btn-whatsapp">
    <i class="fab fa-whatsapp"></i>
</button> -->

<button *ngIf="!showChat" (click)="toggleChat()" class="chat-button btn">
</button>


<div [@openClose]="showChat ? 'open' : 'closed'" *ngIf="showChat" class="chat-window card">

    <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <img src="assets/icons/lucas-face.png" alt="Logo" height="40">
                <h5 class="mb-0 ml-2">Luca$</h5>
            </div>
            <button type="button" class="close" aria-label="Close" (click)="toggleChat()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    
    <div class="card-body chat-background">
        <div #msg *ngFor="let message of messages" 
             [class]="message.sender === 'me' ? 'd-flex justify-content-end mb-4' : 'd-flex justify-content-start mb-4'">
             <div [class]="(message.sender === 'me' ? 'msg_cotainer_send text-justify' : 'msg_cotainer text-justify')">
                {{message.text}}
                <span [class]="message.sender === 'me' ? 'msg_time_send' : 'msg_time'">{{message.time }}</span>
            </div>
            
        </div>

        <div *ngIf="botIsWriting" class="d-flex justify-content-start mb-4">
            <div class="msg_cotainer writing">
                <!-- Escribiendo... -->
                <img src="https://assets-v2.lottiefiles.com/a/90bdd36c-1152-11ee-bdb8-cb8fe6b15cf6/IEEkbwMous.gif" alt="Logo" height="30">
                <!-- <span class="msg_time">Escribiendo...</span> -->
            </div>
        </div>

    </div>
    <div class="card-footer d-flex align-items-center">
        <form [formGroup]="chatForm" (ngSubmit)="sendMessage()" style="display: flex; align-items: center; width: 100%;">
            <input type="text" formControlName="message" class="form-control chat-input mr-2" placeholder="Escribe un mensaje..." style="flex-grow: 1;">
            <button class="btn btn-light send-btn" type="submit">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
        </form>        
    </div>
    
</div>