<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-principal"><b> Promo cupones</b></h1>

                <h1 *ngIf="rol !== 'Administrador'" class="m-0 text-principal">
                    <b> Empresa {{ empresa_desc | titlecase }}</b>
                </h1>

            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['']">Inicio</a>
                    </li>
                    <li class="breadcrumb-item">Cupones</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        
        <!-- /.row -->
    </div>
    
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid w-100">
        <div class="row">
            <div class="col col-12">
                <div class="card card-primary card-outline">
                    <div class="card-header text-right" *ngIf="empresa || rol=='Administrador'">

                        <button [disabled]="!cupoDisponible && rol!=='Administrador'" class="btn btn-info" routerLink="/suscriptores"
                            title="" style="margin-right: 7px;">
                            <i class="fas fa-plus-circle"></i> Suscriptores
                        </button>

                        <button [disabled]="!cupoDisponible && rol!=='Administrador'" class="btn btn-info" routerLink="/planes"
                            title="" style="margin-right: 7px;">
                            <i class="fas fa-plus-circle"></i> Planes
                        </button>

                        <button [disabled]="!cupoDisponible && rol!=='Administrador'" class="btn btn-info" (click)="openModal()"
                            title="">
                            <i class="fas fa-plus-circle"></i> Nuevo cupón
                        </button>
                    </div>
                    <div class="card-body">
                        <table *ngIf="cupones.length > 0" datatable [dtOptions]="dtOptions" class="row-border hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Código</th>
                                    <th>Descripcion</th>
                                    <th style="text-align: center;">Desc. (%)</th>
                                    <th>Registro</th>
                                    <th>Vencimiento</th>
                                    <th>tipo</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of cupones">
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.codigo }}</td>
                                    <td>{{ item.descripcion }}</td>
                                    <td style="text-align: center;">{{ item.descuento }}</td>
                                    <td>{{ item.fecha_inicio | date: 'dd/MM/yyyy' }}</td>
                                    <td>{{ item.fecha_fin | date: 'dd/MM/yyyy' }}</td>
                                    <td>{{ item.tipo }}</td>
                                    <td [class]="
                                                item.estado === 1
                                                ? 'text-success text-bold'
                                                : 'text-danger text-bold'
                                        ">
                                        {{
                                        item.estado === 1
                                        ? 'Activo'
                                        : 'Inactivo'
                                        }}
                                    </td>
                                    <td>
                                        <button (click)="openModal(item)" type="button" class="btn btn-success btn-sm" style="margin-right: 5px;">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-modal-addcupon #modalAddcupon [usuario]="usuarios" (cuponMod)="actualizarCupon($event)"
    (loading)="actualizarCarga($event)"></app-modal-addcupon>
<ngx-loading [show]="loading"></ngx-loading>